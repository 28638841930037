// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['smgbBrand'] = {
    error: null,
    brands: [],
    fetching: false,
}

const slice = createSlice({
    name: 'GolfballsBrands',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getBrandPending(state) {
            state.fetching = true
        },
        getBrandSuccess(state, { payload, type }) {
            state.brands = payload?.data || []
            state.fetching = false
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getBrandsList = () => async () => {
    try {
        dispatch(slice.actions.getBrandPending())
        const response = await axios.get('/brands')
        const { data } = response
        dispatch(slice.actions.getBrandSuccess(data))
    } catch (error) {
        dispatch(slice.actions.hasError(error))
    }
}

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
