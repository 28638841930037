// third-party
import { createSlice } from '@reduxjs/toolkit'

// types
import { DefaultRootStateProps } from 'types'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['user'] = {
    error: null,
    usersS1: [],
    usersS2: [],
    followers: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: [],
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
        },

        // GET USERS STYLE 1
        getUsersListStyle1Success(state, action) {
            state.usersS1 = action.payload
        },

        // GET USERS STYLE 2
        getUsersListStyle2Success(state, action) {
            state.usersS2 = action.payload
        },

        // GET FOLLOWERS
        getFollowersSuccess(state, action) {
            state.followers = action.payload
        },

        // FILTER FOLLOWERS
        filterFollowersSuccess(state, action) {
            state.followers = action.payload
        },

        // GET FRIEND REQUESTS
        getFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload
        },

        // FILTER FRIEND REQUESTS
        filterFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload
        },

        // GET FRIENDS
        getFriendsSuccess(state, action) {
            state.friends = action.payload
        },

        // FILTER FRIENDS
        filterFriendsSuccess(state, action) {
            state.friends = action.payload
        },

        // GET GALLERY
        getGallerySuccess(state, action) {
            state.gallery = action.payload
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.posts = action.payload
        },

        // EDIT COMMENT
        editCommentSuccess(state, action) {
            state.posts = action.payload
        },

        // ADD COMMENT
        addCommentSuccess(state, action) {
            state.posts = action.payload
        },

        // ADD REPLY
        addReplySuccess(state, action) {
            state.posts = action.payload
        },

        // LIKE POST
        likePostSuccess(state, action) {
            state.posts = action.payload
        },

        // LIKE COMMENT
        likeCommentSuccess(state, action) {
            state.posts = action.payload
        },

        // LIKE REPLY
        likeReplySuccess(state, action) {
            state.posts = action.payload
        },

        // GET DETAIL CARDS
        getDetailCardsSuccess(state, action) {
            state.detailCards = action.payload
        },

        // FILTER DETAIL CARDS
        filterDetailCardsSuccess(state, action) {
            state.detailCards = action.payload
        },

        // GET SIMPLE CARDS
        getSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload
        },

        // FILTER SIMPLE CARDS
        filterSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload
        },

        // GET PROFILE CARDS
        getProfileCardsSuccess(state, action) {
            state.profileCards = action.payload
        },

        // FILTER PROFILE CARDS
        filterProfileCardsSuccess(state, action) {
            state.profileCards = action.payload
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
