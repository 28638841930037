import type { ScanResult, Image as ImageType } from 'types/filteredScanResults'

export interface QueryObject {
    [key: string]: string
}

// Greeding With respect to current local time
export const greedingWRTTime = (): string => {
    const commonString = 'Good '
    const nowDate = new Date()
    const hrs = nowDate.getHours()
    if (hrs >= 12 && hrs <= 17) {
        return `${commonString}Afternoon`
    }
    if (hrs >= 17 && hrs <= 24) {
        return `${commonString}Evening`
    }
    return `${commonString}Morning`
}

export const errorStatusChecker = (resFromAPI: any) => {}

// No operation
export const noop = (): void => {}

export const convertStringTo3leftand3Right = (inputString: string): string => {
    if (inputString && inputString.length > 9) {
        // Extract the first three characters and the last three characters
        const firstThree = inputString.substring(0, 3)
        const lastThree = inputString.substring(inputString.length - 3)
        return `${firstThree}...${lastThree}`
    }
    return inputString
}

export const americanTime = (
    dateToConvert: string | Date | undefined,
    withTime: boolean = false
) => {
    if (dateToConvert) {
        const date = new Date(dateToConvert)
        let options: Intl.DateTimeFormatOptions = { dateStyle: 'short' }
        if (withTime) {
            const dateFormat = new Intl.DateTimeFormat('en-US', options)
            const { timeZone } = dateFormat.resolvedOptions()
            options = { ...options, timeStyle: 'long', timeZone }
        }
        const dateToString = new Intl.DateTimeFormat('en-US', options).format(
            date
        )
        return dateToString
    }
    return ''
}

// Check Image size
export const checkIfFilesAreTooBig = (
    sizeToCheck: number,
    file?: File
): boolean => {
    let valid = false
    if (file) {
        const size = file.size / 1024
        valid = size <= sizeToCheck
    }
    return valid
}

// dateFormate
export const dateFormate = (date: Date) =>
    `${date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}/${
        date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`
    }/${date.getFullYear()}`

// Check Dimensions for Image
export const checkIfFilesDimensionsAreCorrect = (
    width: number,
    file?: File
): Promise<boolean> =>
    new Promise((resolve) => {
        if (file) {
            const img = new Image()
            const URL = window.URL || window.webkitURL
            const objectUrl = URL.createObjectURL(file)
            img.onload = () => {
                const aspectRatio = img.width / img.height
                const cond1 = img.width <= width
                const cond2 = aspectRatio === 1
                URL.revokeObjectURL(objectUrl)
                resolve(cond1 && cond2)
            }
            img.src = objectUrl
        } else {
            resolve(false)
        }
    })

//  convert first letter of a string to uppercase
export const capitalizeFirstLetter = (str: string | null): string => {
    // converting first letter to uppercase
    const capitalized = str ? str.charAt(0).toUpperCase() + str.slice(1) : ''

    return capitalized
}

export const getFieldErrorNames = (formikErrors: any) => {
    const transformObjectToDotNotation = (
        obj: any,
        prefix = '',
        result = []
    ) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key]
            if (!value) return

            const nextKey = prefix ? `${prefix}.${key}` : key
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result)
            } else {
                result.push(nextKey as never)
            }
        })

        return result
    }

    return transformObjectToDotNotation(formikErrors)
}

export const getAllParamsFromURL = (
    listOfKeys: string[],
    searchParms: URLSearchParams
): QueryObject => {
    let queries = {}
    listOfKeys.forEach((key) => {
        const val = searchParms.get(key)
        if (val !== null && val !== undefined) {
            queries = { ...queries, [key]: val }
        }
    })
    return queries
}
export const setAllSearchParams = (
    listOfKeys: string[],
    searchParms: URLSearchParams,
    dataToOverWrite: QueryObject
): QueryObject => {
    let queries = {}
    listOfKeys.forEach((key) => {
        const val = searchParms.get(key)
        if (dataToOverWrite[key] !== undefined) {
            if (dataToOverWrite[key] !== '' && dataToOverWrite[key] !== null)
                queries = { ...queries, [key]: dataToOverWrite[key] }
        } else if (val !== null && val !== undefined) {
            queries = { ...queries, [key]: val }
        }
    })
    return queries
}

export const DateToISOForChart = (
    inputDate: Date,
    zeroStart: boolean = false,
    maxEnd: boolean = false
) => {
    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')
    let formattedDateTime = `${year}-${month}-${day}`
    if (zeroStart) {
        formattedDateTime = `${formattedDateTime} 00:00:00`
    } else if (maxEnd) {
        formattedDateTime = `${formattedDateTime} 23:59:59`
    }
    return formattedDateTime
}

export const DateToISO = (inputDate: Date, forApi: boolean = false) => {
    const datePart = DateToISOForChart(inputDate)
    const hour = String(
        inputDate[forApi ? 'getUTCHours' : 'getHours']()
    ).padStart(2, '0')
    const mins = String(
        inputDate[forApi ? 'getUTCMinutes' : 'getMinutes']()
    ).padStart(2, '0')
    return `${datePart} ${hour}:${mins}:00`
}

// Method for sorting by date to the latest
export const sortByLatest = (a: { createdAt: Date }, b: { createdAt: Date }) =>
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()

export const getUniqueObjects = (arr: ScanResult[]): ImageType[] => {
    const arrayToCheck: ImageType[] = arr.flatMap(({ image }) =>
        image.map((iit) => iit)
    )
    // Create an empty Set to store unique paths
    const uniquePaths = new Set<string>()

    // Create an array to store unique objects
    const uniqueObjects: ImageType[] = []

    // Iterate through the input array
    for (const obj of arrayToCheck) {
        // Check if the "path" property is not already present in the Set
        if (!uniquePaths.has(obj.path)) {
            // If it's not present, add the path to the Set and add the object to the result array
            uniquePaths.add(obj.path)
            uniqueObjects.push(obj)
        }
    }

    return uniqueObjects
}

export const checkAndGet = (objectToCheck: any, keyToCheck: string) => {
    if (objectToCheck[keyToCheck]) {
        return objectToCheck[keyToCheck]
    }
    if (objectToCheck?.manufacturer?.[keyToCheck]) {
        return objectToCheck.manufacturer[keyToCheck]
    }
    if (objectToCheck?.construction?.[keyToCheck]) {
        return objectToCheck.construction[keyToCheck]
    }
    return null
}

export const checkIfObjectIsValidToShow = (
    objToCheck: { [key: string]: any },
    arrayOfKeysToCheck: string[]
) => (objToCheck ? arrayOfKeysToCheck.some((it) => objToCheck[it]) : false)

const type1Filler = [
    {
        title: 'New or Mint Condition',
        count: null,
    },
    {
        title: 'Playing Condition',
        count: null,
    },
    {
        title: 'Somewhat Impaired',
        count: null,
    },
    {
        title: 'Impaired Play',
        count: null,
    },
    {
        title: 'Do Not Play',
        count: null,
    },
]
export const chartType1Filler = (
    data: {
        title: string
        count: number | null
    }[]
): {
    title: string
    count: number | null
}[] => {
    const ROPList = type1Filler.reduce(
        (pre, cur) => {
            let tep = [...pre]
            const findThis = data.find((it) => it.title === cur.title)
            tep = [...tep, findThis || cur]
            return tep
        },
        [] as {
            title: string
            count: number | null
        }[]
    )

    const includedTitles = ROPList.map((item) => item.title)
    const remainingData = data.filter(
        (item) => !includedTitles.includes(item.title)
    )
    return [...ROPList, ...remainingData]
}

export const chartType2Filler = (
    inputData: {
        title: string
        count: number | null
    }[],
    starter: string,
    ender: string
): {
    title: string
    count: number | null
}[] => {
    let tData = [...inputData]
    if (starter) {
        const startDate = dateElementSplitter(starter)
        tData = tData.filter(
            (it) => dateElementSplitter(it.title, 'ymd') >= startDate
        )
        const foundIt = tData.findIndex((it) => it.title === starter)
        if (foundIt === -1) {
            tData = [{ title: starter, count: null }, ...tData]
        }
    }
    if (ender) {
        const enderDate = dateElementSplitter(ender)
        tData = tData.filter(
            (it) => dateElementSplitter(it.title, 'ymd') <= enderDate
        )
        const foundIt = tData.findIndex((it) => it.title === ender)
        if (foundIt === -1) {
            tData = [...tData, { title: ender, count: null }]
        }
    }
    return tData
}

const dateElementSplitter = (
    dataString: string,
    order: 'mdy' | 'ymd' = 'mdy'
) => {
    let monthz
    let dayz
    let yearz
    const parts = dataString.split('-')
    if (order === 'mdy') {
        ;[monthz, dayz, yearz] = parts
    } else {
        ;[yearz, monthz, dayz] = parts
    }

    return new Date(
        parseInt(yearz, 10),
        parseInt(monthz, 10) - 1,
        parseInt(dayz, 10)
    )
}

export const adjustDateForParDayScan = (dateString: string) => {
    const [day, month, year] = dateString.split(' ')[0].split('-')
    return `${parseInt(month, 10)}-${parseInt(year, 10)}-${day}`
}

export const formatNumber = (num: number, precision = 2) => {
    const map = [
        { suffix: 'T', threshold: 1e12 },
        { suffix: 'B', threshold: 1e9 },
        { suffix: 'M', threshold: 1e6 },
        { suffix: 'K', threshold: 1e3 },
        { suffix: '', threshold: 1 },
    ]

    const found = map.find((x) => Math.abs(num) >= x.threshold)
    if (found) {
        const formatted =
            (num / found.threshold).toFixed(precision) + found.suffix
        return formatted
    }

    return num
}

export const dateDiffModifier = (currentDate: Date) => {
    const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
    ) // Get the first day of the current month

    const timeDifference = currentDate.getTime() - firstDayOfMonth.getTime()
    const daysDifference = timeDifference / (1000 * 3600 * 24)

    if (daysDifference < 5) {
        // Calculate the first day of the previous month
        const previousMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1,
            1
        )
        return [previousMonth, currentDate]
    }

    return [firstDayOfMonth, currentDate]
}
