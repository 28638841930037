// third-party
import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'
import {
    chartType1Filler,
    chartType2Filler,
    adjustDateForParDayScan,
} from 'utils'

// types
import { DefaultRootStateProps } from 'types'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['dashboard'] = {
    error: null,
    fetching: false,
    notificationFetching: false,
    activeUserFetching: false,
    newBallRequestsFetching: false,
    totalModels: 0,
    totalScans: 0,
    scannedResultsCount: 0,
    uniqueDeviceCount: 0,
    registeredUsers: 0,
    totalUsers: 0,
    totalActive: 0,
    totalActiveUsers: [],
    totalPast1day: 0,
    totalPast1dayUsers: [],
    totalPast7day: 0,
    totalPast7dayUsers: [],
    totalPast30day: 0,
    totalPast30dayUsers: [],
    data: [],
    ropFromDate: null,
    newBallRequests: [],
    notification: [],
    scannedResultsPerDayGraphData: [],
    scannedResultsRateOfPlayabilityGraphData: [],
    dailyNewUsersGraphData: [],
    countList: [],
    allOtherCountListFetching: true,
    models: [],
    count: 0,
    limit: 0,
    offset: 0,
}

const dashboard = createSlice({
    name: 'adminDashboard',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, { payload }) {
            state.error = payload
            state.fetching = false
        },
        getDashboardPending(state) {
            state.fetching = true
        },
        initialize() {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                getDashboard.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => {
                    if (payload?.type === 'rateOfPlaybility') {
                        return {
                            ...state,
                            fetching: false,
                            error: null,
                            scannedResultsRateOfPlayabilityGraphData:
                                payload?.scannedResultsRateOfPlayabilityGraphData
                                    ? chartType1Filler(
                                          payload.scannedResultsRateOfPlayabilityGraphData
                                      )
                                    : [],
                        }
                    }
                    if (payload?.type === 'scans') {
                        return {
                            ...state,
                            fetching: false,
                            error: null,
                            scannedResultsPerDayGraphData:
                                payload?.scannedResultsPerDayGraphData
                                    ? chartType2Filler(
                                          payload.scannedResultsPerDayGraphData,
                                          adjustDateForParDayScan(
                                              payload.starter
                                          ),
                                          adjustDateForParDayScan(payload.ender)
                                      )
                                    : [],
                        }
                    }

                    return {
                        ...state,
                        fetching: false,
                        error: null,
                        ...payload,
                        scannedResultsRateOfPlayabilityGraphData:
                            payload?.scannedResultsRateOfPlayabilityGraphData
                                ? chartType1Filler(
                                      payload.scannedResultsRateOfPlayabilityGraphData
                                  )
                                : [],
                        scannedResultsPerDayGraphData:
                            payload?.scannedResultsPerDayGraphData
                                ? chartType2Filler(
                                      payload.scannedResultsPerDayGraphData,
                                      adjustDateForParDayScan(payload.starter),
                                      adjustDateForParDayScan(payload.ender)
                                  )
                                : [],
                    }
                }
            )
            .addCase(
                getActiveUsers.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => ({
                    ...state,
                    activeUserFetching: false,
                    totalActive: payload?.filter?.totalUsers || 0,
                    totalActiveUsers: payload?.filter?.users || [],
                    totalPast1day:
                        payload?.history?.past24Hours?.totalUsers || 0,
                    totalPast1dayUsers:
                        payload?.history?.past24Hours?.users || [],
                    totalPast7day: payload?.history?.past7Days?.totalUsers || 0,
                    totalPast7dayUsers:
                        payload?.history?.past7Days?.users || [],
                    totalPast30day:
                        payload?.history?.past30Days?.totalUsers || 0,
                    totalPast30dayUsers:
                        payload?.history?.past30Days?.users || [],
                })
            )
            .addCase(
                getPendingNotifications.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => ({
                    ...state,
                    ...(payload?.pagination || {}),
                    notification: [
                        ...state.notification,
                        ...(payload?.data || []),
                    ],
                    notificationFetching: false,
                })
            )
            .addCase(
                getNewBallRequestList.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => ({
                    ...state,
                    newBallRequests: payload,
                    newBallRequestsFetching: false,
                })
            )
            .addCase(
                getAllOtherCountList.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => ({
                    ...state,
                    countList: payload,
                    allOtherCountListFetching: false,
                })
            )
            .addCase(
                getDailyUsersJoinStatus.fulfilled,
                (state: DefaultRootStateProps['dashboard'], { payload }) => ({
                    ...state,
                    dailyNewUsersGraphData: payload,
                })
            )
            .addMatcher(isAnyOf(getPendingNotifications.pending), (state) => {
                state.notificationFetching = true
            })
            .addMatcher(isAnyOf(getActiveUsers.pending), (state) => {
                state.activeUserFetching = true
            })
            .addMatcher(isAnyOf(getDashboard.pending), (state) => {
                state.fetching = true
            })
            .addMatcher(isAnyOf(getAllOtherCountList.pending), (state) => {
                state.newBallRequestsFetching = true
            })
            .addMatcher(isAnyOf(getNewBallRequestList.pending), (state) => {
                state.newBallRequestsFetching = true
            })
            .addMatcher(
                isAnyOf(getDashboard.rejected),
                (
                    state: DefaultRootStateProps['dashboard'],
                    { payload }: any
                ) => {
                    state.error = payload
                    state.fetching = false
                }
            )
            .addMatcher(
                isAnyOf(getDashboard.rejected),
                (
                    state: DefaultRootStateProps['dashboard'],
                    { payload }: any
                ) => {
                    state.error = payload
                    state.allOtherCountListFetching = false
                }
            )
            .addMatcher(
                isAnyOf(getNewBallRequestList.rejected),
                (
                    state: DefaultRootStateProps['dashboard'],
                    { payload }: any
                ) => {
                    state.error = payload
                    state.newBallRequestsFetching = false
                }
            )
            .addMatcher(
                isAnyOf(getActiveUsers.rejected),
                (
                    state: DefaultRootStateProps['dashboard'],
                    { payload }: any
                ) => {
                    state.error = payload
                    state.activeUserFetching = false
                }
            )
    },
})

// ----------------------------------------------------------------------

export const getDashboard = createAsyncThunk(
    'get-adminDashboard',
    async (
        {
            perDayDateFrom,
            perDaydateTo,
            ropDateFrom,
            ropDateTo,
            type,
            ropCount,
        }: {
            perDayDateFrom?: string
            perDaydateTo?: string
            ropDateFrom?: string
            ropDateTo?: string
            type?: 'rateOfPlaybility' | 'scans'
            ropCount?: 'all' | 'to-approve-user-set'
        },
        { rejectWithValue }
    ) => {
        let returner = null
        try {
            const { data } = await axios.get(
                `/users/dashboard?${
                    perDayDateFrom ? `perDayDateFrom=${perDayDateFrom}` : ''
                }${perDaydateTo ? `&perDaydateTo=${perDaydateTo}` : ''}${
                    ropCount ? `ropCount=${ropCount}` : ''
                }${ropDateFrom ? `&ropDateFrom=${ropDateFrom}` : ''}${
                    ropDateTo ? `&ropDateTo=${ropDateTo}` : ''
                }`
            )
            if (type) {
                data.type = type
            }
            if (perDayDateFrom) {
                data.starter = perDayDateFrom
            }
            if (perDaydateTo) {
                data.ender = perDaydateTo
            }
            returner = data
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const getPendingNotifications = createAsyncThunk(
    'get-pendingNotifications',
    async (params: any, { rejectWithValue }) => {
        let returner = null
        try {
            const { data } = await axios.get(
                `/users/notifications?limit=${params.limit}&offset=${params.offset}`
            )

            returner = data
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const getActiveUsers = createAsyncThunk(
    'get-activeUsers',
    async (
        {
            from,
            to,
        }: {
            from: string
            to: string
        },
        { rejectWithValue }
    ) => {
        let returner = null
        try {
            const { data } = await axios.get(
                `/users/active?from=${from}&to=${to}`
            )
            returner = data
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const getDailyUsersJoinStatus = createAsyncThunk(
    'get-dailyUsersJoinStatus',
    async (
        {
            from,
            to,
            userType,
        }: {
            from: string
            to: string
            userType?: 'guest' | 'normal'
        },
        { rejectWithValue }
    ) => {
        let returner = []
        try {
            const { data } = await axios.get(
                `/users/daily-stats?perDayDateFrom=${from}&perDaydateTo=${to}${
                    userType ? `&userType=${userType}` : ''
                }`
            )
            returner = data || []
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const getNewBallRequestList = createAsyncThunk(
    'get-newBallRequestList',
    async (_: any, { rejectWithValue }) => {
        let returner = []
        try {
            const { data } = await axios.get(
                '/user-added-ball?limit=10&offset=0&sortOrder=DESC&sortBy=createdAt'
            )
            returner = data?.data || []
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const getAllOtherCountList = createAsyncThunk(
    'get-allOtherCountList',
    async (_: any, { rejectWithValue }) => {
        let returner = []
        try {
            const { data } = await axios.get('/users/bft-count')
            returner = data || []
        } catch (error) {
            rejectWithValue(error)
        }
        return returner
    }
)
export const makeitEmpty = () => () => {
    dispatch(dashboard.actions.initialize())
}
export const { initialize } = dashboard.actions
// Reducer
export default dashboard.reducer
