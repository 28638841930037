// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['filteredScanResults'] = {
    error: null,
    filteredScanResults: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
    manualResults: [],
    manualCount: 0,
    manualLimit: 0,
    manualOffset: 0,
}

const slice = createSlice({
    name: 'filteredScanResults',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getUserScanResultsPending(state) {
            state.fetching = true
        },
        getScanResultsFinish(state) {
            state.fetching = false
        },
        getUserScanResultsSuccess(state, { payload }) {
            if (payload?.resultType) {
                if (payload?.resultType === 'SCANNING_MODE') {
                    state.filteredScanResults = payload?.data || []
                    state.count = payload?.pagination?.count || 0
                    state.limit = payload?.pagination?.limit || 0
                    state.offset = payload?.pagination?.offset || 0
                } else if (payload?.resultType === 'MANUAL_MODE') {
                    state.manualResults = payload?.data || []
                    state.manualCount = payload?.pagination?.count || 0
                    state.manualLimit = payload?.pagination?.limit || 0
                    state.manualOffset = payload?.pagination?.offset || 0
                }
            }
            state.fetching = false
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getUserScanResultsList =
    ({
        userId,
        brandId = 0,
        fitLevel = '',
        playAbilityRate = '',
        ballFitnessMin = 0,
        ballFitnessMax = 100,
        limit = 25,
        offset = 0,
        dateFrom = '',
        dateTo = '',
        search = '',
        sortBy = 'createdAt',
        sortOrder = 'ASC',
        resultType = 'SCANNING_MODE',
    }: {
        userId: number
        ballFitnessMin?: number
        ballFitnessMax?: number
        fitLevel?: string
        playAbilityRate?: string
        brandId?: number
        limit?: number
        offset?: number
        search?: string
        sortBy?: string
        dateFrom?: string
        dateTo?: string
        sortOrder?: 'ASC' | 'DESC'
        resultType: 'SCANNING_MODE' | 'MANUAL_MODE'
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getUserScanResultsPending())
            // eslint-disable-next-line prettier/prettier
            const request = `/scanned-results/user?resultType=${resultType}&userId=${userId}&limit=${limit}&offset=${offset}${
                search ? `&search=${search}` : ''
            }${brandId ? `&brandId=${brandId}` : ''}${
                fitLevel ? `&fitLevel=${fitLevel}` : ''
            }${`&ballFitnessMin=${ballFitnessMin}`}${`&ballFitnessMax=${ballFitnessMax}`}${
                sortBy ? `&sortBy=${sortBy}` : ''
            }${sortOrder ? `&sortOrder=${sortOrder}` : ''}${
                playAbilityRate ? `&rateOfPlayAbility=${playAbilityRate}` : ''
            }${dateFrom ? `&dateFrom=${dateFrom}` : ''}${
                dateTo ? `&dateTo=${dateTo}` : ''
            }`
            const response = await axios.get(request)
            const { data } = response
            dispatch(
                slice.actions.getUserScanResultsSuccess({ ...data, resultType })
            )
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
export const makeUserScanListEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
