import { lazy } from 'react'

// project imports
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import AuthGuard from 'utils/route-guard/AuthGuard'

// page routing
const UserListing = Loadable(lazy(() => import('views/users')))
const NewBallListing = Loadable(lazy(() => import('views/newGolfballs')))
const ScanningResults = Loadable(lazy(() => import('views/scanningResults')))
const UserDetail = Loadable(lazy(() => import('views/users/userDetail')))
const GolfBallListPage = Loadable(lazy(() => import('views/golfballs')))
const GolfBallDetailPage = Loadable(
    lazy(() => import('views/golfballs/golfballDetail'))
)
const GolfBallManagementPage = Loadable(
    lazy(() => import('views/golfballs/addGolfball'))
)
const ResultTrackingDetail = Loadable(
    lazy(() => import('views/scanningResults/resultsTrackingDetail'))
)
const DashboardPage = Loadable(lazy(() => import('views/dashboard')))
const DamageAnalysisPage = Loadable(lazy(() => import('views/damageAnalysis')))

const BallsViewsReport = Loadable(lazy(() => import('views/reports/ballViews')))
const BallsRecommendationsReport = Loadable(
    lazy(() => import('views/reports/ballsRecommend'))
)
const BallsDamageAnalysisReport = Loadable(
    lazy(() => import('views/reports/damageAnalysis'))
)
const SettingsPage = Loadable(lazy(() => import('views/settings')))
const ROPsPage = Loadable(lazy(() => import('views/rops')))
const ROPEditAdd = Loadable(lazy(() => import('views/rops/addEditROPs')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/golf-balls',
            element: <GolfBallListPage />,
        },
        {
            path: '/golf-balls/:id',
            element: <GolfBallDetailPage />,
        },
        { path: '/golf-balls/edit/:id', element: <GolfBallManagementPage /> },
        { path: '/golf-balls/add', element: <GolfBallManagementPage /> },
        {
            path: '/new-golf-balls',
            element: <NewBallListing />,
        },
        {
            path: '/scanning-results',
            element: <ScanningResults />,
        },
        {
            path: '/scanning-results/:id',
            element: <ResultTrackingDetail />,
        },
        {
            path: '/users',
            element: <UserListing />,
        },
        {
            path: '/users/:id',
            element: <UserDetail />,
        },
        {
            path: '/users/:id',
            element: <UserDetail />,
        },
        {
            path: '/image-labeling/userdata',
            element: <DamageAnalysisPage />,
        },
        {
            path: '/image-labeling/dataset',
            element: <DamageAnalysisPage />,
        },
        {
            path: '/reports/balls-views',
            element: <BallsViewsReport />,
        },
        {
            path: '/reports/balls-recommendations',
            element: <BallsRecommendationsReport />,
        },
        {
            path: '/reports/balls-damage-analysis',
            element: <BallsDamageAnalysisReport />,
        },
        {
            path: '/settings',
            element: <SettingsPage />,
        },
        {
            path: '/rops',
            element: <ROPsPage />,
        },
        {
            path: '/rops/add',
            element: <ROPEditAdd />,
        },
        {
            path: '/rops/edit/:id',
            element: <ROPEditAdd />,
        },
        {
            path: '/',
            element: <DashboardPage />,
        },
        {
            path: '/dashboard',
            element: <DashboardPage />,
        },
    ],
}

export default MainRoutes
