// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['newGolfBallRequests'] = {
    error: null,
    newRequests: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
}

const slice = createSlice({
    name: 'newRequests',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getNewRequestPending(state) {
            state.fetching = true
        },
        getNewRequestFinish(state) {
            state.fetching = false
        },
        getNewRequestSuccess(state, { payload }) {
            state.newRequests = payload?.data
            state.count = payload?.pagination?.count
            state.limit = payload?.pagination?.limit
            state.offset = payload?.pagination?.offset
            state.fetching = false
        },
        removeNewRequest(state, { payload }) {
            const tempArr = [...state.newRequests]
            tempArr.splice(payload.index, 1)
            state.newRequests = tempArr
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getNewBallRequestList =
    ({
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'createdAt',
        sortOrder = 'ASC',
        reviewed = '',
    }: {
        limit?: number
        offset?: number
        search?: string
        sortBy?: string
        reviewed?: string
        sortOrder?: 'ASC' | 'DESC'
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getNewRequestPending())
            const response = await axios.get(
                `/user-added-ball?limit=${limit}&offset=${offset}${
                    search ? `&search=${search}` : ''
                }${sortBy ? `&sortBy=${sortBy}` : ''}${
                    sortOrder ? `&sortOrder=${sortOrder}` : ''
                }${reviewed ? `&reviewed=${reviewed}` : ''}`
            )
            const { data } = response
            dispatch(slice.actions.getNewRequestSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
export const removeNewGolfBall =
    ({ id, index }: { id: number; index: number }) =>
    async () => {
        try {
            await axios.delete(`/user-added-ball?id=${id}`)
            dispatch(slice.actions.removeNewRequest({ index }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
