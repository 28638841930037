// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import type { DefaultRootStateProps } from 'types'
import type { Settings } from 'types/smgbSettings'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['smgbSettings'] = {
    error: null,
    settings: {
        damageThreshold: '0',
        enableBannerAdMob: 'no',
        bannerAdMobKey: '',
    },
    fetching: true,
}

const slice = createSlice({
    name: 'Settings',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getSettingsPending(state) {
            state.fetching = true
        },
        getSettingsSuccess(state, { payload }) {
            if (payload?.data) {
                state.settings = payload.data
            }
            state.fetching = false
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
type SettingItemToSendType = {
    parameter: string
    value: string
}

export const getSettings = () => async (): Promise<Settings | null> => {
    let returner: Settings | null = null
    try {
        dispatch(slice.actions.getSettingsPending())
        const response = await axios.get('/users/app-config')
        const { data } = response
        if (response.status === 200) {
            returner = data.data
            dispatch(slice.actions.getSettingsSuccess(data))
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error))
    }
    return returner
}
export const setSettings =
    (dataToSend: SettingItemToSendType[]) => async (): Promise<string | {}> => {
        let errorMessage = ''
        try {
            dispatch(slice.actions.getSettingsPending())
            const response = await axios.patch('/users/app-config', dataToSend)
            if (response.status === 200 || response.status === 201) {
                dispatch(slice.actions.getSettingsSuccess({ data: dataToSend }))
            }
        } catch (error: any) {
            errorMessage = error
            dispatch(slice.actions.hasError(error))
        }
        return errorMessage
    }

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
