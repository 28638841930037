// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'
import { User } from 'types/smgbusers'
import { BreakdownSimple, BreakdownType } from 'types/breakdown'
// ----------------------------------------------------------------------

export interface BreakDownData {
    breakdown: BreakdownType[]
    toFilterByBrand: BreakdownSimple[]
    recommendations: BreakdownSimple[]
}

export interface UserCountry {
    title: string
    count: 0
}

const initialState: DefaultRootStateProps['smgbUser'] = {
    error: null,
    users: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
}

const slice = createSlice({
    name: 'smgbUser',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getUserPending(state) {
            state.fetching = true
        },
        getUserDetailFinish(state) {
            state.fetching = false
        },
        getUsersSuccess(state, { payload, type }) {
            state.users = payload?.data || []
            state.count = payload?.pagination?.count || 0
            state.limit = payload?.pagination?.limit || 0
            state.offset = payload?.pagination?.offset || 0
            state.fetching = false
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getUsersList =
    ({
        dateFrom = '',
        dateTo = '',
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'udid',
        sortOrder = 'ASC',
        filterUserType = 'normal',
        filterByCountry = '',
    }: {
        dateFrom?: string
        dateTo?: string
        filterUserType?: string
        filterByCountry?: string | null
        limit?: number
        offset?: number
        search?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getUserPending())
            const response = await axios.get(
                `/users?limit=${limit}&offset=${offset}&filterUserType=${filterUserType}${
                    search ? `&search=${search}` : ''
                }${
                    filterByCountry
                        ? `&filterByCountry=${(
                              filterByCountry as string
                          )?.toLowerCase()}`
                        : ''
                }${sortBy ? `&sortBy=${sortBy}` : ''}${
                    dateFrom ? `&dateFrom=${dateFrom}` : ''
                }${dateTo ? `&dateTo=${dateTo}` : ''}${
                    sortOrder ? `&sortOrder=${sortOrder}` : ''
                }`
            )
            const { data } = response
            dispatch(slice.actions.getUsersSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
export const getUserDetail = (id: string) => async (): Promise<User | null> => {
    let result: User | null = null
    try {
        dispatch(slice.actions.getUserPending())
        const { data } = await axios.get(`/users/${id}/details`)
        result = data
    } catch (error) {
        dispatch(slice.actions.hasError(error))
    } finally {
        dispatch(slice.actions.getUserDetailFinish())
    }
    return result
}

export const getUserRecommendation =
    (id: string) => async (): Promise<BreakDownData | null> => {
        let result: BreakDownData | null = null
        try {
            dispatch(slice.actions.getUserPending())
            const { data } = await axios.get(
                `/breakdown-fitness-score/${id}/each-question-answer`
            )
            result = data
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.getUserDetailFinish())
        }
        return result
    }

export const getUserCountries = () => async (): Promise<UserCountry[]> => {
    let result: UserCountry[] = []
    try {
        const { data } = await axios.get('/users/count-by-country')
        result = data
    } catch (error) {
        console.log('getUserCountriesError', error)
    }
    return result
}

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
