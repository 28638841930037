// third-party
import { combineReducers } from 'redux'

// project imports
import snackbar from './slices/snackbar'
import user from './slices/user'
import menu from './slices/menu'
import smgbUser from './slices/smgbUsers'
import smgbGolfball from './slices/golfballs'
import smgbBrand from './slices/brands'
import dashboard from './slices/adminPortal'
import newGolfBallRequests from './slices/smgbNewRequests'
import userScanResults from './slices/userScanResults'
import filteredScanResults from './slices/FilteredUserScanResults'
import damageAnalysisResults from './slices/damageAnalysis'
import smgbReports from './slices/reports'
import smgbSettings from './slices/settings'
import smgbROPs from './slices/smgbROPs'

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    user,
    menu,
    snackbar,
    smgbUser,
    smgbGolfball,
    smgbBrand,
    dashboard,
    newGolfBallRequests,
    userScanResults,
    filteredScanResults,
    damageAnalysisResults,
    smgbReports,
    smgbSettings,
    smgbROPs,
})

export default reducer
