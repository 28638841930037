import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

declare global {
    interface Window {
        gtag: any
    }
}
const pathSplitter = (pathname: string) => pathname.split('/')[1]
const usePageTracking = (): void => {
    const location = useLocation()

    useEffect(() => {
        const spliced = pathSplitter(location.pathname)
        const title = `SMGB - ${
            spliced
                ? spliced.charAt(0).toUpperCase() +
                  spliced.slice(1, spliced.length)
                : 'Dashboard'
        }`
        document.title = title
        window.gtag('event', 'page_view', {
            page_path: location.pathname + location.search + location.hash,
            page_search: location.search,
            page_hash: location.hash,
        })
    }, [location])
}

export default usePageTracking
