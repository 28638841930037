// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'

export type ROP =
    | 'Do Not Play'
    | 'Impaired Play'
    | 'Somewhat Impaired'
    | 'Playing Condition'
    | 'New or Mint Condition'
// ----------------------------------------------------------------------
const initialState: DefaultRootStateProps['damageAnalysisResults'] = {
    error: null,
    damageAnalysisData: [],
    datasetImageLabeling: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
}

const slice = createSlice({
    name: 'damageAnalysis',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getDamageAnalysisResultsPending(state) {
            state.fetching = true
        },
        getDamageAnalysisResultsFinish(state) {
            state.fetching = false
        },
        getDamageAnalysisResultsSuccess(state, { payload }) {
            state[
                payload?.dataFrom === 'dataset'
                    ? 'datasetImageLabeling'
                    : 'damageAnalysisData'
            ] = payload?.data?.data || []
            state.count = payload?.data?.pagination?.count || 0
            state.limit = payload?.data?.pagination?.limit || 0
            state.offset = payload?.data?.pagination?.offset || 0
            state.fetching = false
        },
        updateDamageAnalysisResults(state, { payload }) {
            const { imageId, dataFrom } = payload || {
                imageId: 0,
                dataFrom: 'app',
            }
            const localData = [
                ...state[
                    dataFrom === 'dataset'
                        ? 'datasetImageLabeling'
                        : 'damageAnalysisData'
                ],
            ]
            const foundIt = localData.findIndex((it) => it.id === imageId)
            if (foundIt > -1) {
                localData.splice(foundIt, 1)

                state[
                    dataFrom === 'dataset'
                        ? 'datasetImageLabeling'
                        : 'damageAnalysisData'
                ] = localData
            }
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getDamageAnalysisData =
    ({
        rateOfPlayAbility,
        sortBy = 'createdAt',
        limit = 12,
        offset = 0,
        sortOrder = 'DESC',
        discardImage = false,
        dataFrom = 'app',
    }: {
        limit: number
        rateOfPlayAbility?: ROP
        sortBy: 'createdAt' | 'updatedAt'
        offset: number
        sortOrder?: 'ASC' | 'DESC'
        discardImage?: boolean
        dataFrom?: 'app' | 'dataset'
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getDamageAnalysisResultsPending())
            const request = `/scanned-images?dataFrom=${dataFrom}&${
                rateOfPlayAbility
                    ? `rateOfPlayAbility=${rateOfPlayAbility}&`
                    : ''
            }discardImage=${discardImage}&limit=${limit}&offset=${offset}&sortOrder=${sortOrder}&sortBy=${sortBy}`
            const response = await axios.get(request)
            dispatch(
                slice.actions.getDamageAnalysisResultsSuccess({
                    ...response,
                    dataFrom,
                })
            )
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }

export const markAsLabeled = async ({
    imageId,
    adminROP,
    discard = false,
    dataFrom = 'app',
}: {
    imageId: number
    adminROP: ROP
    discard?: boolean
    dataFrom?: 'app' | 'dataset'
}): Promise<void> => {
    dispatch(
        slice.actions.updateDamageAnalysisResults({
            imageId,
            dataFrom,
        })
    )
    try {
        await axios.post(`/scanned-images`, {
            imageId,
            adminROP,
            discard,
            dataFrom,
        })
    } catch (error) {
        // dispatch(slice.actions.hasError(error))
    }
}

export const updateToAnotherLabeled = async ({
    id,
    adminROP,
    discard = false,
    dataFrom = 'app',
}: {
    id: number
    adminROP: ROP
    discard?: boolean
    dataFrom?: 'app' | 'dataset'
}): Promise<void> => {
    dispatch(
        slice.actions.updateDamageAnalysisResults({
            imageId: id,
            adminROP,
            dataFrom,
        })
    )
    try {
        await axios.patch(`/scanned-images/${id}`, {
            adminROP,
            discard,
            dataFrom,
        })
    } catch (error) {
        // dispatch(slice.actions.hasError(error))
    }
}

export const makeItEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
