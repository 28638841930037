import { Suspense, LazyExoticComponent, ComponentType } from 'react'

// material-ui
import { LinearProgressProps } from '@mui/material/LinearProgress'

// project imports
import Loader from './Loader'

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

interface LoaderProps extends LinearProgressProps {}

const Loadable = (
    Component:
        | LazyExoticComponent<() => JSX.Element>
        | ComponentType<React.ReactNode>
) =>
    // eslint-disable-next-line func-names
    function (props: LoaderProps) {
        return (
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        )
    }

export default Loadable
