// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import type { DefaultRootStateProps } from 'types'
import type { reportType } from 'types/smgbReports'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['smgbReports'] = {
    error: null,
    paginatedBallsViewsReport: {
        data: [],
        pagination: { count: 0, offset: 0, limit: 0 },
    },
    paginatedBallsRecommendationsReport: {
        data: [],
        pagination: { count: 0, offset: 0, limit: 0 },
    },
    paginatedBallsDamageAnalysisReport: {
        data: [],
        pagination: { count: 0, offset: 0, limit: 0 },
    },
    fetching: false,
    downloading: false,
    downloadError: null,
}

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getReportPending(state) {
            state.fetching = true
        },
        getReportSuccess(state, { payload }) {
            state[
                payload.type === 'views'
                    ? 'paginatedBallsViewsReport'
                    : 'paginatedBallsRecommendationsReport'
            ] = payload?.data
                ? { data: payload?.data, pagination: payload?.pagination }
                : {
                      data: [],
                      pagination: { count: 0, offset: 0, limit: 0 },
                  }
            state.fetching = false
        },
        getDamageReportSuccess(state, { payload }) {
            state.paginatedBallsDamageAnalysisReport = payload?.data
                ? { data: payload?.data, pagination: payload?.pagination }
                : {
                      data: [],
                      pagination: { count: 0, offset: 0, limit: 0 },
                  }
            state.fetching = false
        },
        getDownloadSuccess(state) {
            state.downloading = false
        },
        getDownloadPending(state) {
            state.downloading = true
        },
        hasDownloadingError(state, action) {
            state.downloadError = action.payload
            state.downloading = false
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getReportList =
    ({
        dateFrom = '',
        dateTo = '',
        limit = 25,
        offset = 0,
        sortBy = 'count',
        sortOrder = 'ASC',
        filterBy = 'all',
        scanningMode = '',
        currentReportType = 'views',
        top = 0,
    }: {
        dateFrom?: string
        dateTo?: string
        filterBy?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        currentReportType?: reportType
        limit?: number
        offset?: number
        top?: number
        scanningMode?: string
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getReportPending())
            const response = await axios.get(
                `/scanned-results/${
                    currentReportType === 'recommendation'
                        ? 'recommendations'
                        : 'ball-views'
                }`,
                {
                    params: getReportParams({
                        dateFrom,
                        dateTo,
                        limit,
                        offset,
                        sortBy,
                        sortOrder,
                        filterBy,
                        scanningMode,
                        currentReportType,
                        top,
                    }),
                }
            )
            const { data } = response
            dispatch(
                slice.actions.getReportSuccess({
                    type: currentReportType,
                    data: data.data,
                    pagination: data.pagination,
                })
            )
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }

export const getDamageReportList =
    ({
        damageROPLabel,
        damageConfidenceMin = 1,
        damageConfidenceMax = 100,
        limit = 25,
        offset = 0,
        sortBy = 'count',
        sortOrder = 'ASC',
    }: {
        damageConfidenceMin?: number
        damageConfidenceMax?: number
        damageROPLabel?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        limit?: number
        offset?: number
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getReportPending())
            const response = await axios.get(
                'scanned-results/damage-analysis',
                {
                    params: {
                        damageConfidenceMin,
                        damageConfidenceMax,
                        damageROPLabel,
                        limit,
                        offset,
                        sortBy,
                        sortOrder,
                    },
                }
            )
            const { data } = response
            dispatch(
                slice.actions.getDamageReportSuccess({
                    data: data.data,
                    pagination: data.pagination,
                })
            )
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}

export const downloadReport =
    ({
        dateFrom = '',
        dateTo = '',
        limit = 25,
        offset = 0,
        sortBy = 'count',
        sortOrder = 'ASC',
        filterBy = 'all',
        scanningMode = '',
        currentReportType = 'views',
        top = 0,
    }: {
        dateFrom?: string
        dateTo?: string
        filterBy?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        currentReportType?: reportType
        limit?: number
        offset?: number
        top?: number
        scanningMode?: string
    }) =>
    async () => {
        dispatch(slice.actions.getDownloadPending())
        try {
            const response = await axios.get(
                `/scanned-results/${
                    currentReportType === 'recommendation'
                        ? 'recommendations'
                        : 'ball-views'
                }-report`,
                {
                    responseType: 'blob',
                    params: getReportParams({
                        dateFrom,
                        dateTo,
                        limit,
                        offset,
                        sortBy,
                        sortOrder,
                        filterBy,
                        scanningMode,
                        currentReportType,
                        top,
                    }),
                }
            )
            const { data } = response

            const filename = `${
                currentReportType === 'views'
                    ? 'ball-views'
                    : 'ball-recommendations'
            }-${filterBy || 'all'}_${getDateWithFileTypeString('csv')}`

            getDownload(data, filename)
            return data
        } catch (error) {
            dispatch(slice.actions.hasDownloadingError(error))
        } finally {
            dispatch(slice.actions.getDownloadSuccess())
        }
        return null
    }

export const downloadDamageReport =
    ({
        damageROPLabel,
        damageConfidenceMin = 1,
        damageConfidenceMax = 100,
        limit = 25,
        offset = 0,
        sortBy = 'count',
        sortOrder = 'ASC',
    }: {
        damageConfidenceMin?: number
        damageConfidenceMax?: number
        damageROPLabel?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        limit?: number
        offset?: number
    }) =>
    async () => {
        dispatch(slice.actions.getDownloadPending())
        try {
            const response = await axios.get(
                '/scanned-results/damage-analysis-report',
                {
                    responseType: 'blob',
                    params: {
                        damageConfidenceMin,
                        damageConfidenceMax,
                        damageROPLabel,
                        limit,
                        offset,
                        sortBy,
                        sortOrder,
                    },
                }
            )
            const { data } = response

            const filename = `damage-analysis-${
                damageROPLabel || 'all'
            }_${getDateWithFileTypeString('csv')}`
            getDownload(data, filename)
            return data
        } catch (error) {
            dispatch(slice.actions.hasDownloadingError(error))
        } finally {
            dispatch(slice.actions.getDownloadSuccess())
        }
        return null
    }

const getDownload = (data: any, filename: string) => {
    const link = document.createElement('a')
    const blob = new Blob([data], { type: 'application/octet-stream' })
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
}

const getDateWithFileTypeString = (fileType: string) =>
    `${new Date().toString().slice(0, 21).replace(/[: ]/g, '-')}.${fileType}`

const getReportParams = ({
    dateFrom = '',
    dateTo = '',
    limit = 25,
    offset = 0,
    sortBy = 'count',
    sortOrder = 'ASC',
    filterBy = 'all',
    scanningMode = '',
    currentReportType = 'views',
    top = 0,
}: {
    dateFrom?: string
    dateTo?: string
    filterBy?: string
    sortBy?: string
    sortOrder?: 'ASC' | 'DESC'
    currentReportType?: reportType
    limit?: number
    offset?: number
    top?: number
    scanningMode?: string
}) => ({
    top: filterBy === 'all' ? undefined : top,
    to: dateTo || undefined,
    from: dateFrom || undefined,
    filter: filterBy || undefined,
    scanningMode: currentReportType === 'views' ? scanningMode : undefined,
    sortBy,
    sortOrder,
    limit,
    offset,
})
