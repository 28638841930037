import React, { createContext, useEffect, useReducer } from 'react'

// third-party
import jwtDecode from 'jwt-decode'

// reducer - state management
import { LOGIN, LOGOUT } from 'store/actions'
import accountReducer from 'store/accountReducer'

// project imports
import Loader from 'ui-component/Loader'
import axios from 'utils/axios'

// types
import { KeyedObject } from 'types'
import { InitialLoginContextProps, JWTContextType } from 'types/auth'

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
}

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false
    }
    const decoded: KeyedObject = jwtDecode(serviceToken)
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000
}

const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken)
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`
    } else {
        localStorage.removeItem('serviceToken')
        delete axios.defaults.headers.common.Authorization
    }
}

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null)

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState)

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken')
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken)
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                email: 'admin@scanmygolfball.com',
                                name: `Admin`,
                            },
                        },
                    })
                } else {
                    dispatch({
                        type: LOGOUT,
                    })
                }
            } catch (err) {
                dispatch({
                    type: LOGOUT,
                })
            }
        }

        init()
    }, [])

    const login = async (
        email: string,
        password: string,
        ErrorCallback?: (e: any) => void
    ) => {
        try {
            const response = await axios.post('/auth/login', {
                email,
                password,
            })

            if (response.status === 200 || response.status === 201) {
                const { accessToken, profile } = response.data
                setSession(accessToken)
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: profile,
                    },
                })
            }
        } catch (e: any) {
            ErrorCallback?.(e)
        }
    }

    const logout = async () => {
        try {
            await axios.delete('/auth/logout')
        } catch (e) {
            console.log('LogoutApiError', e)
        }
        setSession(null)
        dispatch({ type: LOGOUT })
    }

    const simpleLogout = async () => {
        setSession(null)
        dispatch({ type: LOGOUT })
    }

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                login,
                logout,
                simpleLogout,
            }}
        >
            {children}
        </JWTContext.Provider>
    )
}

export default JWTContext
