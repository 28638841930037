import axios from 'axios'
import * as React from 'react'
import { dispatch } from 'store'
import { openSnackbar } from 'store/slices/snackbar'
import useAuth from 'hooks/useAuth'
import errorsPlaceHolders from 'utils/errorsAndPlaceHolders'

const axiosServices = axios.create({
    timeout: 60000,
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: { accept: 'application/json', 'Content-Type': 'application/json' },
})

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error.response?.data || 'Wrong Services')
)

const AxiosInterceptor = ({ children }: { children: React.ReactElement }) => {
    const { simpleLogout } = useAuth()

    const openSnackError = (message: string) => {
        dispatch(
            openSnackbar({
                open: true,
                message,
                variant: 'alert',
                transition: 'SlideLeft',
                alert: {
                    color: 'error',
                },
            })
        )
    }

    const Logout = async () => {
        try {
            simpleLogout()
            openSnackError(errorsPlaceHolders.apiSessionExpired)
        } catch (err) {
            //
        }
    }

    React.useEffect(() => {
        const resInterceptor = (response: any) => response

        const manageErrorConnection = async (err: any) => {
            const { status } = err.response || {}
            if (status === 401) {
                // 401 should be handled as logout
                await Logout()
            } else if (status === 404) {
                // 404 should not show generic message | NotFoundException
                openSnackError(errorsPlaceHolders.apiNotFound)
            } else if (status === 400) {
                // 400 should be handled on slice level | ValidateMessage
            }
            return Promise.reject(err)
        }

        const interceptor = axiosServices.interceptors.response.use(
            resInterceptor,
            manageErrorConnection
        )

        return () => axiosServices.interceptors.response.eject(interceptor)
    }, [])

    return children
}
export default axiosServices
export { AxiosInterceptor }
