// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import type { DefaultRootStateProps } from 'types'
import type { ROPItem } from 'types/smgbRoPs'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['ROPs'] = {
    error: null,
    ROPList: [],
    fetching: true,
    postingLoading: false,
}

const slice = createSlice({
    name: 'ROPs',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getROPsPending(state) {
            state.fetching = true
        },
        getROPsFinish(state) {
            state.fetching = false
        },
        getROPsPostingPending(state) {
            state.postingLoading = true
        },
        getROPsPostingFinish(state) {
            state.postingLoading = false
        },
        getROPsSuccess(state, { payload }) {
            if (payload?.data) {
                state.ROPList = payload.data
            }
            state.fetching = false
        },
        updateROPsSuccess(state, { payload }) {
            if (payload?.data) {
                state.ROPList = state.ROPList.map((item) =>
                    item.id === payload.data.id ? payload.data : item
                )
            }
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getROPsList = () => async (): Promise<ROPItem | null> => {
    let returner: ROPItem | null = null
    try {
        dispatch(slice.actions.getROPsPending())
        const response = await axios.get('/scanned-results/damage-labels')
        const { data } = response
        if (response.status === 200) {
            returner = data.data
            dispatch(slice.actions.getROPsSuccess(data))
        }
    } catch (error) {
        dispatch(slice.actions.hasError(error))
    }
    return returner
}

export const addNewOrEditROPLabel =
    ({ id, ...rest }: ROPItem) =>
    async (): Promise<null | EditAddError> => {
        let err: null | EditAddError = null
        try {
            dispatch(slice.actions.getROPsPostingPending())
            const response = await (id
                ? axios.patch(`/scanned-results/${id}/damage-label`, rest)
                : axios.post(`/scanned-results/damage-label`, rest))
            if (id) {
                if (response.status === 200) {
                    dispatch(
                        slice.actions.updateROPsSuccess({
                            data: { id, ...rest },
                        })
                    )
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            err = error as EditAddError
            console.log(`Error while ${id ? 'editing' : 'creating'}`, error)
        } finally {
            dispatch(slice.actions.getROPsPostingFinish())
        }
        return err
    }
export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
export interface EditAddError {
    statusCode: number
    message: string | string[]
    error: string
}
