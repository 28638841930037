// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'
import { Golfball, GolfBallDetail } from 'types/smgbgolfballs'

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['smgbGolfball'] = {
    error: null,
    golfballs: [],
    flagShipBall: null,
    fetching: false,
    addingGolfballLoading: false,
    count: 0,
    limit: 0,
    offset: 0,
}

const slice = createSlice({
    name: 'Golfballs',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getGolfballPending(state) {
            state.fetching = true
        },
        addEditGolfballPending(state) {
            state.addingGolfballLoading = true
        },
        addEditGolfballSuccess(state) {
            state.addingGolfballLoading = false
        },
        getGolfballDetailFinish(state) {
            state.fetching = false
        },
        getGolfBallSuccess(state, { payload }) {
            state.golfballs = payload?.data || []
            state.count = payload?.pagination?.count || 0
            state.limit = payload?.pagination?.limit || 0
            state.offset = payload?.pagination?.offset || 0
            state.flagShipBall = payload.flagShipBall
            state.fetching = false
        },
        setModelAsFlagShip(state, { payload }) {
            if (payload.isFlagship === 'no') {
                const newList = state.golfballs.map((it) => ({
                    ...it,
                    isFlagship: null,
                }))
                state.golfballs = newList
                state.flagShipBall = null
            } else {
                const foundIt = state.golfballs.findIndex(
                    (it) => it.id === payload.id
                )
                if (foundIt > -1) {
                    const newList = state.golfballs.map((it, idx) => ({
                        ...it,
                        isFlagship: idx === foundIt ? 'yes' : null,
                    }))
                    state.golfballs = newList
                    state.flagShipBall = newList[foundIt]
                }
            }
        },
        initialize() {
            return initialState
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getGolfBallList =
    ({
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'id',
        sortOrder = 'ASC',
        brandId = '',
        balls = 'all',
    }: {
        limit?: number
        offset?: number
        search?: string

        brandId?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        balls?: CurrentInMarket
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getGolfballPending())
            const response = await axios.get('/models', {
                params: {
                    limit,
                    offset,
                    sortBy,
                    sortOrder,
                    balls,
                    brandId: brandId || undefined,
                    search: search || undefined,
                },
            })
            const { data } = response
            dispatch(slice.actions.getGolfBallSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
export const setThisModelAsFlagship =
    (id: number, isFlagship: 'yes' | 'no' = 'yes') =>
    async (): Promise<void> => {
        try {
            dispatch(slice.actions.setModelAsFlagShip({ id, isFlagship }))
            await axios.post(`/models/flagship`, {
                id,
                isFlagship,
            })
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
export const getGolfBallDetail =
    (id: string) => async (): Promise<Golfball | null> => {
        let result: Golfball | null = null
        try {
            dispatch(slice.actions.getGolfballPending())
            const { data } = await axios.get(`/models/${id}/details`)
            result = data.data
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        } finally {
            dispatch(slice.actions.getGolfballDetailFinish())
        }
        return result
    }

export const addNewOrEditGolfBall =
    (ballDetails: GolfBallDetail) => async (): Promise<null | EditAddError> => {
        let err: null | EditAddError = null
        try {
            dispatch(slice.actions.addEditGolfballPending())
            await (ballDetails.modelId
                ? axios.put(`/models/${ballDetails.modelId}`, ballDetails)
                : axios.post(`/models`, ballDetails))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            err = error as EditAddError
            console.log(
                `Error while ${ballDetails.modelId ? 'editing' : 'Creating'}`,
                error
            )
        } finally {
            dispatch(slice.actions.addEditGolfballSuccess())
        }
        return err
    }

export const editGolfBall =
    (ballDetails: GolfBallDetail) => async (): Promise<null | EditAddError> => {
        let err: null | EditAddError = null
        try {
            dispatch(slice.actions.addEditGolfballPending())
            await axios.put(`/models/${ballDetails.modelId}`, ballDetails)
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            err = error as EditAddError
            console.log('Error while editing', error)
        } finally {
            dispatch(slice.actions.addEditGolfballSuccess())
        }
        return err
    }
export const deleteGolfBall =
    (id: number) => async (): Promise<null | EditAddError> => {
        let err: null | EditAddError = null
        try {
            dispatch(slice.actions.getGolfballPending())
            await axios.delete(`/models/${id}`)
        } catch (error) {
            dispatch(slice.actions.hasError(error))
            err = error as EditAddError
            console.log('Error while deleteing', error)
        }
        return err
    }

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}

export interface EditAddError {
    statusCode: number
    message: string | string[]
    error: string
}

export type CurrentInMarket = 'all' | 'market-balls'
