// routing
import Routes from 'routes'
// project imports
import usePageTracking from 'hooks/usePageTracking'
import Locales from 'ui-component/Locales'
import NavigationScroll from 'layout/NavigationScroll'
import RTLLayout from 'ui-component/RTLLayout'
import Snackbar from 'ui-component/extended/Snackbar'
import { AxiosInterceptor } from 'utils/axios'
import ThemeCustomization from 'themes'
import { JWTProvider as AuthProvider } from 'contexts/JWTContext'

// ==============================|| APP ||============================== //

const App = () => {
    usePageTracking()

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <AxiosInterceptor>
                                <>
                                    <Routes />
                                    <Snackbar />
                                </>
                            </AxiosInterceptor>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    )
}

export default App
