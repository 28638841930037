// third-party
import { FormattedMessage } from 'react-intl'
// assets
import {
    IconUserCircle,
    IconGolf,
    IconHome,
    IconPlus,
    IconScan,
    IconTestPipe,
    IconReport,
    IconBallBowling,
} from '@tabler/icons'
import { NavItemType } from 'types'
// ==============================|| PAGES & MENU ITEMS ||============================== //

const mainMenu = {
    id: 'smgb-group',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/dashboard',
            icon: IconHome,
            breadcrumbs: false,
        },
        {
            id: 'golf-balls',
            title: <FormattedMessage id="golf-balls" />,
            type: 'item',
            url: '/golf-balls',
            icon: IconGolf,
            breadcrumbs: false,
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: IconUserCircle,
            breadcrumbs: false,
        },
        {
            id: 'scanning-results',
            title: <FormattedMessage id="scanning-results" />,
            type: 'item',
            url: '/scanning-results',
            icon: IconScan,
            breadcrumbs: false,
        },
        {
            id: 'new-golf-balls',
            title: <FormattedMessage id="new-golf-balls" />,
            type: 'item',
            url: '/new-golf-balls',
            icon: IconPlus,
            breadcrumbs: false,
        },
        {
            id: 'rops',
            title: <FormattedMessage id="rops" />,
            type: 'item',
            url: '/rops',
            icon: IconBallBowling,
            breadcrumbs: false,
        },
        {
            id: 'image-labeling',
            type: 'collapse',
            title: <FormattedMessage id="image-labeling" />,
            icon: IconTestPipe,
            children: [
                {
                    id: 'userdata',
                    title: <FormattedMessage id="userdata" />,
                    type: 'item',
                    url: '/image-labeling/userdata',
                    breadcrumbs: false,
                },
                {
                    id: 'dataset',
                    title: <FormattedMessage id="dataset" />,
                    type: 'item',
                    url: '/image-labeling/dataset',
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: 'reports',
            type: 'collapse',
            title: <FormattedMessage id="reports" />,
            icon: IconReport,
            children: [
                {
                    id: 'balls-views',
                    title: <FormattedMessage id="ballsViews" />,
                    type: 'item',
                    url: '/reports/balls-views',
                    breadcrumbs: false,
                },
                {
                    id: 'balls-recommendations',
                    title: <FormattedMessage id="ballsRecommendations" />,
                    type: 'item',
                    url: '/reports/balls-recommendations',
                    breadcrumbs: false,
                },
                {
                    id: 'balls-damage-analysis',
                    title: <FormattedMessage id="damageAnalysis" />,
                    type: 'item',
                    url: '/reports/balls-damage-analysis',
                    breadcrumbs: false,
                },
            ],
        },
    ],
}
// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [mainMenu],
}

export default menuItems
