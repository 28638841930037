// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'utils/axios'
import { dispatch } from '../index'

// types
import { DefaultRootStateProps } from 'types'
import { ScanResult } from 'types/filteredScanResults'
// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['userScanResults'] = {
    error: null,
    scanResults: [],
    fetching: false,
    count: 0,
    limit: 0,
    offset: 0,
    ROPs: [],
}

const slice = createSlice({
    name: 'scanResults',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload
            state.fetching = false
        },
        getScanResultsPending(state) {
            state.fetching = true
        },
        getScanResultsFinish(state) {
            state.fetching = false
        },
        getScanResultsSuccess(state, { payload, type }) {
            state.scanResults = payload?.data || []
            state.count = payload?.pagination?.count || 0
            state.limit = payload?.pagination?.limit || 0
            state.offset = payload?.pagination?.offset || 0
            state.fetching = false
        },
        getROPS(state, { payload }) {
            state.ROPs = payload
        },
        initialize(state) {
            return { ...initialState, ROPs: state.ROPs }
        },
    },
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------

export const getScanResultsList =
    ({
        brandId = 0,
        fitLevel = '',
        dateFrom = '',
        dateTo = '',
        resultType = 'MANUAL_MODE',
        playAbilityRate = '',
        ballFitnessMin = 0,
        ballFitnessMax = 100,
        limit = 25,
        offset = 0,
        search = '',
        sortBy = 'createdAt',
        sortOrder = 'ASC',
    }: {
        dateFrom?: string
        dateTo?: string
        ballFitnessMin?: number
        ballFitnessMax?: number
        fitLevel?: string
        playAbilityRate?: string
        brandId?: number
        limit?: number
        offset?: number
        search?: string
        sortBy?: string
        sortOrder?: 'ASC' | 'DESC'
        resultType?: 'SCANNING_MODE' | 'MANUAL_MODE'
    }) =>
    async () => {
        try {
            dispatch(slice.actions.getScanResultsPending())
            // eslint-disable-next-line prettier/prettier
            const request = `/scanned-results?resultType=${resultType}&limit=${limit}&offset=${offset}${
                search ? `&search=${search}` : ''
            }${brandId ? `&brandId=${brandId}` : ''}${
                fitLevel ? `&fitLevel=${fitLevel}` : ''
            }${`&ballFitnessMin=${ballFitnessMin}`}${`&ballFitnessMax=${ballFitnessMax}`}${
                sortBy ? `&sortBy=${sortBy}` : ''
            }${sortOrder ? `&sortOrder=${sortOrder}` : ''}${
                playAbilityRate ? `&rateOfPlayAbility=${playAbilityRate}` : ''
            }${dateFrom ? `&dateFrom=${dateFrom}` : ''}${
                dateTo ? `&dateTo=${dateTo}` : ''
            }`
            const response = await axios.get(request)
            const { data } = response
            dispatch(slice.actions.getScanResultsSuccess(data))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }

export const getROPs =
    (isDamagaAnalysis = false) =>
    async () => {
        try {
            const response = await axios.get(
                `/scanned-results/rop-list?isDamagaAnalysis=${isDamagaAnalysis}`
            )
            const { data } = response
            dispatch(slice.actions.getROPS(data))
        } catch (error) {
            console.log('gettingROPsError:', error)
        }
    }

export const getIntermediateImages = async (
    id: string
): Promise<ScanResult[] | null> => {
    let result: ScanResult[] | null = []
    try {
        const { data } = await axios.get(
            `/scanned-results/details?scanIdentifier=${id}`
        )
        result = data.data
    } catch (error) {
        // dispatch(slice.actions.hasError(error))
    }
    return result
}

export const makeitEmpty = () => () => {
    dispatch(slice.actions.initialize())
}
